import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import "../styles/main.css"
import { Helmet } from "react-helmet"


const IndexPage = () => {
    return (

    <main className="studio">
      <Helmet>  
        <title>Martin Zerr – Fotostudio</title>
      </Helmet> 


        <a className="back" href="/">zurück</a>
        <h1>Fotografie im Fotostudio</h1>

        <div className="studio_wrapper">
            <div className="inside leftside">
            <Zoom><StaticImage src="../images/Studio_01.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_02.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_03.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_04.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_05.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_06.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_07.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_08.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_09.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>

        </div> 

            <div className="inside rightside">
            <Zoom><StaticImage src="../images/Studio_10.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_11.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_12.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_13.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_14.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_15.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_16.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_17.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Studio_18.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>


            </div>
        </div>


        <div className="samowar">
            <Zoom><StaticImage src="../images/samowar.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/teekanne.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        </div>

        <a className="kontakt" href="/kontakt">Kontakt</a>


    </main>

    )
}

export default IndexPage